import {Component, Input, OnInit} from '@angular/core';
import {PaymentFrequencyFacade} from '../../application/payment-frequency.facade';

@Component({
  selector: 'payment-frequency',
  templateUrl: './payment-frequency.component.html',
  styleUrls: ['./payment-frequency.component.scss']
})
export class PaymentFrequencyComponent implements OnInit {
  @Input() company_id: string;

  constructor(public paymentFrequencyFacade: PaymentFrequencyFacade) { }

  toggleActiveState(id: number, is_active: boolean) {
    this.paymentFrequencyFacade.savePaymentFrequency(id, parseInt(this.company_id,0), is_active);
  }

  ngOnInit(): void {
    this.paymentFrequencyFacade.loadPaymentFrequencies(parseInt(this.company_id, 0));
  }

}
