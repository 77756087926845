import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClientsComponent } from './components/clients/clients.component';
import { ClientsInfoComponent } from './components/clients-info/clients-info.component';
import { UsersComponent } from './components/users/users.component';
import { UsersAddComponent } from './components/users-add/users-add.component';
import { UsersInfoComponent } from './components/users-info/users-info.component';
import { AuthGuard, AuthLoginGuard}  from './service';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate:[AuthLoginGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard] },
  { path: 'clients', component: ClientsComponent, canActivate:[AuthGuard] },
  { path: 'clients/:id', component: ClientsInfoComponent, canActivate:[AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate:[AuthGuard] },
  { path: 'users/add', component: UsersAddComponent, canActivate:[AuthGuard] },
  { path: 'users/:id', component: UsersInfoComponent, canActivate:[AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
