import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PaymentMethod} from '../domain/interface';
import {HttpClient} from '@angular/common/http';
import {API} from '../../../../environments';
import {getAuthorizationHeader} from '../../shared/util';

@Injectable()
export class PaymentMethodService {
  constructor(private http: HttpClient) {}

  public getPaymentMethods(company_id: string): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(`${API}/company/payment_method`,
      {
        headers: getAuthorizationHeader(),
        params: {
          company_id
        }
      });
  }

  public setPaymentMethod(id: number, company_id: number, is_active: boolean) {
    return this.http.post(`${API}/company/payment_method`, {
      id,
      company_id,
      is_active
    }, {
      headers: getAuthorizationHeader()
    });
  }
}
