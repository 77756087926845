import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {catchError, tap, map, finalize} from "rxjs/operators";
import {environment as env} from "../../../environments/environment";

const API: string = env.urls.api;


@Injectable()
export class AuthGuard implements CanActivate {
  private test: any;
  constructor(
    private router: Router,
    private http: HttpClient
  ){}

  canActivate(): Observable<boolean> {

    let token = JSON.parse(localStorage.getItem('auth')) ? JSON.parse(localStorage.getItem('auth')) : null;
    let bool: Observable<any>;

    if(!token) {
      this.router.navigate(['/']);
    }

    bool = this.http.post(`${API}/authenticate/token/validate`, {}, {
      headers: {
        authorization: token.accessToken ? token.accessToken : null
      }
    }).pipe(
      catchError(res => {
        if (res.status === 401) {
          this.router.navigate(['/']);
        }
        return [];
      }),
      finalize(() => {
        return [true];
      })
    );

    bool.subscribe(val => {this.test = val})

    if (this.test) {
      return of(true);
    } else {
      this.router.navigate(['/']);
    }
    return bool
  }}
