import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppFacade } from 'src/app/facades/app.facade';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.scss']
})
export class UsersAddComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private appFacade: AppFacade,
    private router: Router
  ) { 

    this.form = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });

  }

  ngOnInit() {  }

  saveButtonPressed() {
    this.appFacade.addUser(this.form.value).subscribe(data => {
      this.router.navigate(['users']);
    }, error => {
      alert('Er is iets fout gegaan. Probeer het a.u.b. opnieuw.');
    });
  }

}
