import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Trial from "../interfaces/trial";
import { environment as env } from 'src/environments/environment';
import { map } from "rxjs/operators";

const API: string = env.urls.trial_period;

@Injectable({
    providedIn: 'root'
})
export class TrialService {
    constructor(private http: HttpClient) {}

    private getHeaders(): HttpHeaders {
        const authDetails = JSON.parse(localStorage.getItem('auth'));
        const accessToken = authDetails.accessToken; // your token
    
        return new HttpHeaders({
          'Content-Type': 'application/json',
          'Authentication': accessToken
        });
    }

    public GetTrialByCompanyID(company_id: number): Observable<Trial> {
        return this.http.get<Trial>(`${API}/trial/${company_id}`, {
            headers: this.getHeaders()
        }).pipe(map((response) => {
            response.deleted = response.hasOwnProperty('deleted_at')
            return response;
        }))
    }

    public CancelTrialByID(id: number): void {
        this.http.request('delete',`${API}/cancel?transfer_to_free=true`, {
            body: {
                trial_period_ids: [id]
            }
        }).subscribe();
    }

    public ExtendTrialByCompanyID(company_id: number) {
        this.http.post(`${API}/extend`, {
            company_id
        },{
            headers: this.getHeaders()
        }).subscribe();
    }

    public DeleteTrialByID(id: number): void {
        this.http.delete(`${API}/trial/delete/${id}`, {
            headers: this.getHeaders()
        }).subscribe()
    }
}