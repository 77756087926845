import { AuthLoginGuard } from './auth/auth-login.guard';
import {AuthGuard} from "./auth/auth.guard";

export const APP_GUARDS = [
  AuthLoginGuard,
  AuthGuard,
];

export {
  AuthLoginGuard,
  AuthGuard,
}
