import {BehaviorSubject, Observable} from 'rxjs';

export class Toggle {
  private state: BehaviorSubject<boolean>;
  public state$: Observable<boolean>;

  constructor(state: boolean) {
    this.state = new BehaviorSubject<boolean>(state);
    this.state$ = this.state.asObservable();
  }

  public setState(state: boolean) {
    this.state.next(state);
  }
}
