export function getPaymentFrequencyName(name: string): string {
  switch (name) {
    case 'monthly':
      return 'Maandelijks';
      break;
    case 'quarterly':
      return 'Driemaandelijks';
      break;
    case 'yearly':
      return 'Jaarlijks';
      break;
  }
}
