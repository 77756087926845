import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';

const API: string = env.urls.api;
const IAM: string = env.urls.iam;

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  private getAccessToken(): string {
    try {
      const authDetails = JSON.parse(localStorage.getItem('auth'));
      const accessToken = authDetails.accessToken; // your token
      return accessToken;
    } catch (e) {
      throw Error(e);
    }
  }

  private getAdminHeaders(): HttpHeaders {
    const token = this.getAccessToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authentication': token
    });
    return headers;
  }

  public getUsers(): Observable<any> {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });

    return this.http.get(`${API}/users`, { headers: headers});
  }

  public addUser(credentials: any): Observable<any> {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });

    return this.http.post(`${API}/users`, credentials, { headers: headers});
  }

  public changeUser(credentials: any): Observable<any> {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });

    return this.http.patch(`${API}/user`, credentials, { headers: headers});
  }

  public adminLogin(companyId: string, email: string): Observable<any> {
    const headers = this.getAdminHeaders();
    return this.http.post(`${IAM}/admin/login`, {
        company_id: companyId,
        email
    }, {
      headers
    });
  }
}
