import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'toplists',
  templateUrl: './toplists.component.html',
  styleUrls: ['./toplists.component.scss']
})
export class ToplistsComponent implements OnInit {

  @Input() statistics: any;

  constructor() { }

  ngOnInit() {
  }

}
