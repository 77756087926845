<section>
  <ng-container *ngIf="paymentFrequencyFacade.paymentFrequencies$ | async">
    <table class="table table-striped">
      <tbody>
        <tr *ngFor="let paymentFrequency of paymentFrequencyFacade.paymentFrequencies$ | async">
          <td>
            <span>{{ paymentFrequency.name }}</span>
          </td>
          <td>
            <shared-ui-toggle [toggle]="paymentFrequency.toggle"
                              (changeState)="toggleActiveState(paymentFrequency.id, $event)"></shared-ui-toggle>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</section>
