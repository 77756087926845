import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment as env} from "../../../environments/environment";
import {Router} from "@angular/router";

const API: string = env.urls.api;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
  }

  logout() {
    if (confirm("Weet je zeker dat je wil uitloggen?")) {

      let token = JSON.parse(localStorage.getItem('auth'));

      this.http.delete(`${API}/authenticate/token`, {
        headers: {
          authorization: token.accessToken
        }
      }).subscribe(() => {
        localStorage.removeItem('auth');
        this.router.navigate(['/']);
      });
    }
  }
}
