import {Injectable} from "@angular/core";

@Injectable()

export class UtilsService {

  nameForPlanId(id: number): string {
    switch (id) {
      case 1:
        return 'Spruit';
      case 2:
        return 'Moestuin';
      case 3:
        return 'Kas';
      case 9:
        return 'Enterprise';
      case 5:
        return 'Forever Free (legacy)';
      case 6:
        return 'Essentials';
      case 7:
        return 'Team';
      case 8:
        return 'Professional';
      case 9:
        return 'Enterprise (legacy)';
      case 10:
        return 'Forever Free';
      case 11:
        return 'Standard';
      case 12:
        return 'Business';
      case 13:
        return 'Enterprise';
      case 14:
        return 'Starter';
    }
    return 'Onbekend';
  }
}
