import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  @Input() statistics: any;

  constructor() { }

  ngOnInit() {
  }

}
