import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {environment as env} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";

const API: string = env.urls.api;

@Injectable({
  providedIn: 'root'
})
export class AuthLoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private http: HttpClient
  ){}

  canActivate(): Observable<boolean> {
    let token = JSON.parse(localStorage.getItem('auth'));
    let bool: Observable<any>;
    let accessToken = token ? token.accessToken : "dummytoken";

    bool = this.http.post(`${API}/authenticate/token/validate`, {}, {
        headers: {
          authorization: accessToken
        },
        observe: "response",
      }).pipe(
        map(res => {
          if(res.status === 200) {
            this.router.navigate(['dashboard'])
          } else {
            return [true];
          }
        }),
        catchError(res => {
          if (res.status === 401) {
            return [true];
          } else {
            this.router.navigate(['dashboard']);
          }
          return [];
        })
      );
      return bool
  }
}
