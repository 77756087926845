import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PaymentFrequencyService} from '../infastructure/payment-frequency.service';
import {filter, map, take} from 'rxjs/operators';
import {showErrorNotification} from '../util';
import {ToastrService} from 'ngx-toastr';
import {PaymentToggle} from '../domain/api/payment-toggle';
import {PaymentToggleFrequency} from '../domain/api/payment-toggle-frequency';

@Injectable()
export class PaymentFrequencyFacade {
  private paymentFrequencies: BehaviorSubject<PaymentToggleFrequency[]>;
  public paymentFrequencies$: Observable<PaymentToggleFrequency[]>;

  constructor(private paymentFrequencyService: PaymentFrequencyService, private toastrService: ToastrService) {
    this.paymentFrequencies = new BehaviorSubject<PaymentToggleFrequency[]>([]);
    this.paymentFrequencies$ = this.paymentFrequencies.asObservable();
  }

  public loadPaymentFrequencies(company_id: number): void {
    this.paymentFrequencyService.getPaymentFrequency(company_id.toString())
      .pipe(
        map((paymentFrequency): PaymentToggle[] => paymentFrequency.map(
          (frequency) => {
            const paymentToggle = new PaymentToggleFrequency(frequency);
            return paymentToggle;
          }))
      )
      .subscribe((paymentFrequencies) => {
        this.paymentFrequencies.next(paymentFrequencies);
      });
  }

  private containsPaymentFrequency(paymentFrequencies: PaymentToggle[], id): boolean {
    const frequency = paymentFrequencies.filter((paymentFrequency) => paymentFrequency.id === id);
    if (frequency.length > 0) {
      return true;
    }
    return false;
  }

  private setPaymentFrequencyState(id: number, is_active: boolean): void {
    this.paymentFrequencies$.pipe(
      take(1),
      filter((paymentFrequencies) => this.containsPaymentFrequency(paymentFrequencies, id)),
      map((paymentFrequencies): PaymentToggle => {
        const paymentFrequency = paymentFrequencies.filter((frequency) => {
          if (frequency.id === id) {
            return frequency;
          }
        });
        return paymentFrequency.pop();
      })
    ).subscribe((paymentToggle) => {
      paymentToggle.toggle.setState(is_active);
    });
  }

  public savePaymentFrequency(id: number, company_id: number, is_active: boolean): void {
    this.setPaymentFrequencyState(id, is_active);
    this.paymentFrequencyService.setPaymentFrequency(id, company_id, is_active)
      .subscribe({
        error: () => {
          this.setPaymentFrequencyState(id, !is_active);
          showErrorNotification(this.toastrService);
        }
      });
  }
}
