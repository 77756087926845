import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Toggle} from '../../domain/entity';
import {take} from 'rxjs/operators';

@Component({
  selector: 'shared-ui-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Output() changeState: EventEmitter<boolean>;
  @Input() toggle: Toggle;
  public form: FormGroup;

  constructor() {
    this.changeState = new EventEmitter<boolean>();
  }

  toggleInput() {
    this.toggle.state$.pipe(
      take(1)
    ).subscribe((state) => {
      this.changeState.emit(!state);
    });
  }

  ngOnInit(): void {
  }

}
