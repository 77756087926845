<section>
  <div class="toggle">
    <label class="switch">
      <input type="checkbox"
             [checked]="toggle.state$ | async"
             (change)="toggleInput()">
      <span class="slider round"></span>
    </label>
  </div>
</section>
