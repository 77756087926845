import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

const API: string = env.urls.api;

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  public getStatistics(): Observable<any> {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });

    return this.http.get(`${API}/statistics`, { headers: headers});
  }

}
