<app-nav></app-nav>
<div class="container">
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item mr-auto">
          <h3>Klanten</h3>
        </li>
        <li class="nav-item margin-form">
          <input type="text" (keydown)="keyDownFunction($event)" (input)="searchString=$event.target.value" class="form-control" placeholder="Naam van bedrijf">
        </li>
        <li class="nav-item">
          <button class="btn wm-button btn-block right-align" (click)="searchCompanies()" type="submit">Zoeken</button>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Bedrijfsnaam</th>
            <th>Pakket</th>
            <th>Actieve gebruikers</th>
            <th>Gedeactiveerde gebruikers</th>
            <th>Gesprekken deze maand</th>
            <th>Gesprekken vorige maand</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of clients">
            <td>
              <a class="selectable" (click)="clientInfoPressed(client)">
                {{companyName(client.name)}}
                </a>
            </td>
            <td>
              {{ nameForPlanId(client.planId) }}
            </td>
            <td> {{ client.active_users }}</td>
            <td> {{ client.deactivated_users }}</td>
            <td> {{ client.total_conversations_this_month }}</td>
            <td> {{ client.total_conversations_previous_month }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
