<form [formGroup]="form" class="form-login">
  <img class="mb-4 logo-login" src="/assets/images/WatermelonLogoWordmarkColor.svg" alt="Watermelon logo" width="300px">

  <input formControlName="email" type="email" class="form-control" placeholder="Email">
  <input formControlName="password" type="password" class="form-control" placeholder="Wachtwoord">

  <div class="checkbox mb-3">
    <label>
      <input type="checkbox" value="remember-me"> Wachtwoord onthouden
    </label>
  </div>
  <button class="btn btn-lg btn-block btn-login" (click)="loginUser()" type="submit">Inloggen</button>
  <p class="mt-5 mb-3 text-muted">&copy; {{ year }} - Watermelon</p>
</form>
