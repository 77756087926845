import {Component, OnInit} from '@angular/core';
import {AppFacade} from 'src/app/facades/app.facade';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-users-info',
  templateUrl: './users-info.component.html',
  styleUrls: ['./users-info.component.scss']
})
export class UsersInfoComponent implements OnInit {

  public form: FormGroup;

  public userData = JSON.parse(localStorage.getItem('userData'));

  constructor(
    private appFacade: AppFacade,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      firstName: [this.userData.firstName, Validators.compose([Validators.required])],
      lastName: [this.userData.lastName, Validators.compose([Validators.required])],
      email: [this.userData.email, Validators.compose([Validators.required])],
      id: [this.userData.id, Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {

  }

  saveButtonPressed() {
    this.appFacade.changeUser(this.form.value).subscribe(data => {
      this.router.navigate(['users']);
    }, error => {
      alert('Er is iets fout gegaan. Probeer het a.u.b. opnieuw.');
    });
  }

}
