import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'statistics-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss'],
})
export class StatCardComponent implements OnInit {

  @Input() data: any;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
