export function getPaymentMethodName(name: string): string {
  switch (name) {
    case 'sepa_debit':
      return 'Automatische incasso';
      break;
    case 'ideal':
      return 'iDeal';
      break;
    case 'card':
      return 'Creditcard';
      break;
    case 'manual':
      return 'Bankoverschrijving';
      break;
  }
}
