<app-nav></app-nav>
<div class="container">
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item mr-auto">
          <h3>Toevoegen</h3>
        </li>
        <li class="nav-item">
            <button class="btn wm-button btn-block right-align" (click)="saveButtonPressed()" type="submit">Opslaan</button>
          </li>
      </ul>
    </div>
    <div class="card-body">
      <form [formGroup]="form">
        <table class="table table-striped">
          <tbody>
            <tr>
                <td>
                    Voornaam
                </td>
              <td>
                  <input formControlName="firstName" class="form-control" type="text">
              </td>
            </tr>
            <tr>
                <td>
                    Achternaam
                </td>
              <td>
                  <input formControlName="lastName" class="form-control" type="text">
              </td>
            </tr>
            <tr>
                <td>
                    Email
                </td>
              <td>
                  <input formControlName="email" class="form-control" type="text">
              </td>
            </tr>
            <tr>
                <td>
                    Wachtwoord
                </td>
              <td>
                  <input formControlName="password" class="form-control" type="password">
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
