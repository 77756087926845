import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

const API: string = env.urls.api;

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  constructor(
    private http: HttpClient
  ) { }

  private getHeaders(): HttpHeaders {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
  }

  public login(credentials: any): Observable<any> {
    return this.http.post(`${API}/authenticate/token`, credentials);
  }


  public getPulseBetaUserData(company_id: number): Observable<any> {
    return this.http.get(`${API}/company/${company_id}/pulse/beta`, {
      headers: this.getHeaders()
    });
  }

  public updatePulseBeta(company_id: number): Observable<any> {
    return this.http.post(`${API}/company/${company_id}/pulse/beta`, {}, {
      headers: this.getHeaders()
    });
  }

  public getPulseChatbotLimit(company_id: number): Observable<any>{
    return this.http.get(`${API}/company/${company_id}/pulse/chatbots`, {
      headers: this.getHeaders()
    });
  }

  public updatePulseChatbotLimit(company_id: number, amount:number): Observable<any>{
    return this.http.put(`${API}/company/${company_id}/pulse/chatbots`, {amount: amount}, {
      headers: this.getHeaders()
    });
  }
}
