import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppFacade } from 'src/app/facades/app.facade';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;

  currentTime = new Date();
  year = this.currentTime.getFullYear();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private appFacade: AppFacade
  ) {

    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });

  }

  ngOnInit() {}

  public loginUser() {
    if (!!this.form.value.email && !!this.form.value.password) {
      this.appFacade.login(this.form.value).subscribe(login => {
        this.router.navigate(['dashboard']);
      }, error => {
        alert('Er is een verkeerde combinatie van gebruikersnaam en wachtwoord opgegeven.');
      });
    } else {
        alert('Vul a.u.b. uw gebruikersnaam en wachtwoord in.');
    }
  }

}
