import { Component, OnInit } from '@angular/core';
import { AppFacade } from 'src/app/facades/app.facade';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  statistics: any;

  statisticLoaded: boolean = false;

  constructor(
    private appFacade: AppFacade
  ) { }

  ngOnInit() {
    this.appFacade.getStatistics().subscribe(data => {
      if (data) {
        this.statisticLoaded = true;
        this.statistics = data;
      }
    });

  }

}
