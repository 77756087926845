import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import {changeDetails} from "../interfaces/companyDetails.interface";

const API: string = env.urls.api;

@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  constructor(
    private http: HttpClient
  ) { }

  public getCompanies(): Observable<any> {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });

    return this.http.get(`${API}/companies?limit=15`, { headers: headers});
  }

  public searchCompanies(searchString: string): Observable<any> {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get(`${API}/companies/find/${searchString}`, { headers: headers });
  }

    public changeCompanyDetails(companyId: number, data: changeDetails) {
      const authDetails = JSON.parse(localStorage.getItem('auth'));
      const accessToken = authDetails.accessToken; // your token

      const headers = new HttpHeaders( {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + accessToken
      })

      return this.http.patch(`${API}/company/${companyId}`, data, { headers: headers, observe: 'response' });
    }
}
