<section>
  <ng-container *ngIf="paymentMethodFacade.paymentMethods$ | async">
    <table class="table table-striped">
      <tbody>
      <tr *ngFor="let paymentMethod of paymentMethodFacade.paymentMethods$ | async">
        <td>
          <span>{{ paymentMethod.name }}</span>
        </td>
        <td>
          <shared-ui-toggle [toggle]="paymentMethod.toggle"
                            (changeState)="toggleActiveState(paymentMethod.id, $event)"></shared-ui-toggle>
        </td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</section>
