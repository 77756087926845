import {HttpHeaders} from '@angular/common/http';

export function getAuthorizationHeader(): HttpHeaders {
  const authDetails = JSON.parse(localStorage.getItem('auth'));
  const accessToken = authDetails.accessToken;

  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + accessToken
  });
  return headers;
}
