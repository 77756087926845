import { Component, OnInit } from '@angular/core';
import { AppFacade } from 'src/app/facades/app.facade';
import { Router } from '@angular/router';
import { UtilsService } from '../../service/utils.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  clients: any[];
  searchString: string;
  private initialClientList: any[];

  constructor(
    private appFacade: AppFacade,
    private router: Router,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    this.appFacade.getCompanies().subscribe(companies => {
      const ordered = this.orderCompanyUsers(companies.companies);
      this.clients = ordered;
      this.initialClientList = ordered;
    });
  }

  orderCompanyUsers(companies: any) {

    for (let i = 0; i < companies.length; i++) {
      if (companies[i].users) {
        const users = companies[i].users;
        const waterMelonUsers = users.filter((result) => {
          if (result.email.includes('@watermelon.co')) {
            return result;
          }
        });

        const companyUsers = users.filter((result) => {
          if (!result.email.includes('@watermelon.co')) {
            return result;
          }
        });


        companies[i].users = [
          ...companyUsers,
          ...waterMelonUsers
        ];
      }
    }

    return companies
  }


  clientInfoPressed(clientData: any) {
    localStorage.setItem('clientData', JSON.stringify(clientData));
    this.router.navigate(['clients/' + clientData.id]);
  }

  searchCompanies() {
    this.appFacade.searchCompanies(this.searchString).subscribe(companies => {
      this.clients = this.orderCompanyUsers(companies.companies);
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      if (this.searchString !== '') {
        this.searchCompanies();
      } else {
        this.clients = this.initialClientList;
      }
    }
  }

  nameForPlanId(id: number): string {
    return this.utils.nameForPlanId(id)
  }

  public companyName(name: string): string{
    if (name && name.length > 0) {
      return name;
    } else { return 'No company name'; }
  }

}
