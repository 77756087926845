// NgModule
import { NgModule, LOCALE_ID } from '@angular/core';

// Declarations
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavComponent } from './components/nav/nav.component';
import { ClientsComponent } from './components/clients/clients.component';
import { ClientsInfoComponent } from './components/clients-info/clients-info.component';
import { UsersComponent } from './components/users/users.component';
import { UsersInfoComponent } from './components/users-info/users-info.component';
import { UsersAddComponent } from './components/users-add/users-add.component';
import { StatisticsComponent } from './components/dashboard/statistics/statistics.component';
import { ToplistsComponent } from './components/dashboard/toplists/toplists.component';
import { StatCardComponent } from './components/dashboard/statistics/stat-card/stat-card.component';

// Imports
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// Providers
import localeNL from '@angular/common/locales/nl';
import { AppFacade } from './facades/app.facade';
import { UtilsService } from './service/utils.service';
import { APP_GUARDS } from './service';

// Locale
import { registerLocaleData } from '@angular/common';
import {PaymentModule} from './libs/payment/payment.module';
registerLocaleData(localeNL, 'nl-NL');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NavComponent,
    ClientsComponent,
    ClientsInfoComponent,
    UsersComponent,
    UsersInfoComponent,
    UsersAddComponent,
    StatisticsComponent,
    ToplistsComponent,
    StatCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PaymentModule,
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    AppFacade,
    UtilsService,
    APP_GUARDS,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
