<div class="card">
  <div class="card-header">
    <ul class="nav nav-pills card-header-pills">
      <li class="nav-item mr-auto">
        <h3>Top lists</h3>
      </li>
    </ul>
  </div>
  <div class="card-deck">

    <div class="card">
      <div class="card-header">
      </div>
      <div class="card-body">
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="my-0 font-weight-normal">Top 5: Logins per bedrijf</h5>
      </div>
      <div class="card-body">
        <table width="100%">
          <tr *ngFor="let company of statistics?.topCompanyLogins">
            <td>{{company.companyName}}</td>
            <td>{{company.totalTimesLoggedIn}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
      </div>
      <div class="card-body">
      </div>
    </div>


  </div>

</div>
