import { Injectable } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { CompanyService } from '../service/company.service';
import { UserService } from '../service/user.service';
import { DashboardService } from '../service/dashboard.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {changeDetails} from "../interfaces/companyDetails.interface";

@Injectable()

export class AppFacade {

    constructor(
        private authenticationService: AuthenticationService,
        private companyService: CompanyService,
        private userService: UserService,
        private dashboardService: DashboardService
    ) {}

    // LOGIN

    public login(credentials: any): Observable<any> {
        return this.authenticationService.login(credentials).pipe(map(data => {

            localStorage.setItem('auth', JSON.stringify({
                accessExpire: data.accessExpire,
                accessToken: data.accessToken,
                firstName: data.firstName,
                lastName: data.lastName,
                refreshExpire: data.refreshExpire,
                refreshToken: data.refreshToken,
                userId: data.userId
            }));

        }));
    }

    // STATISTICS

    public getStatistics(): Observable<any> {
        return this.dashboardService.getStatistics().pipe(map(data => {
            return data;
        }));
    }

    // COMPANIES

    public getCompanies(): Observable<any> {
        return this.companyService.getCompanies().pipe(map(data => {
            return data;
        }));
    }

    public changeCompanyDetails(companyId: any, data: changeDetails) {
      return this.companyService.changeCompanyDetails(companyId, data).pipe(map(data => {
        return data;
      }))
    }

    public searchCompanies(searchString: string): Observable<any> {
        return this.companyService.searchCompanies(searchString).pipe(map(data => {
            return data;
        }));
    }

    // CLIENTS

    public getUsers(): Observable<any> {
        return this.userService.getUsers().pipe(map(data => {
            return data;
        }));
    }

    public addUser(credentials: any): Observable<any> {
        return this.userService.addUser(credentials).pipe(map(data => {
            return data;
        }));
    }

    public changeUser(credentials: any): Observable<any> {
        return this.userService.changeUser(credentials).pipe(map(data => {
            return data;
        }));
    }

}
