import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaymentFrequency} from '../domain/interface/payment-frequency';
import {Observable} from 'rxjs';
import {API} from '../../../../environments';
import {getAuthorizationHeader} from '../../shared/util';

@Injectable()
export class PaymentFrequencyService {
  constructor(private http: HttpClient) {}

  public getPaymentFrequency(company_id: string): Observable<PaymentFrequency[]> {
    return this.http.get<PaymentFrequency[]>(`${API}/company/payment_frequency`,
      {headers: getAuthorizationHeader(), params: {
        company_id
        }});
  }

  public setPaymentFrequency(id: number, company_id: number, is_active: boolean) {
    return this.http.post(`${API}/company/payment_frequency`, {
      id,
      company_id,
      is_active
    }, {headers: getAuthorizationHeader()});
  }
}
