<nav class="navbar navbar-expand-lg wm-nav">
    <div class="container">
        <a class="navbar-brand" href="#">
            <img src="/assets/images/WatermelonLogoWordmarkMonochromeLight.svg" width="160" alt="Logo Watermelon">
        </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a [routerLink]="['/dashboard']" [routerLinkActive]="['active']" class="nav-link" routerLink="/dashboard">Dashboard</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/clients']" [routerLinkActive]="['active']" class="nav-link" routerLink="/clients">Klanten</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/users']" [routerLinkActive]="['active']" class="nav-link" routerLink="/users">Gebruikers</a>
          </li>
        </ul>
        <span class="navbar-text">
          <a class="nav-link" (click)="logout()"  routerLink="/">Uitloggen</a>
        </span>
      </div>
    </div>
  </nav>
