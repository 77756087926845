import { Component, OnInit } from '@angular/core';
import { AppFacade } from 'src/app/facades/app.facade';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: any[];

  constructor(
    private appFacade: AppFacade,
    private router: Router,
  ) { }

  ngOnInit() {
    this.appFacade.getUsers().subscribe(juiceUsers => {
      this.users = juiceUsers;
    }); 
  }

  addUserPressed() {
    this.router.navigate(['users', 'add']);
  }

  userInfoPressed(userData: any) {
    localStorage.setItem('userData', JSON.stringify(userData));
    this.router.navigate(['users/' + userData.id]);
  }

}
