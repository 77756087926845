import {Component, Input, OnInit} from '@angular/core';
import {PaymentMethodFacade} from '../../application/payment-method.facade';

@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  @Input() company_id: string;

  constructor(public paymentMethodFacade: PaymentMethodFacade) { }

  toggleActiveState(id: number, is_active: boolean) {
    this.paymentMethodFacade.savePaymentMethod(id, parseInt(this.company_id, 0), is_active);
  }

  ngOnInit(): void {
    this.paymentMethodFacade.loadPaymentMethods(this.company_id);
  }

}
