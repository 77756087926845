export const environment = {
  production: false,
  urls: {
    api: 'https://api2-stage.watermelon.ai/juice/v1',
    iam: 'https://api2-stage.watermelon.ai/iam/v1',
    settings: 'https://api2-stage.watermelon.ai/settings/v1',
    platform: 'https://stage.app.watermelon.ai',
    trial_period: 'https://dev.trial-period.watermelon.ai/trial_period/v1'
  }
};

