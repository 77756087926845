<app-nav></app-nav>
<div class="container">
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item mr-auto">
          <h3>Gebruikers</h3>
        </li>
        <li class="nav-item">
            <button class="btn wm-button btn-block right-align" (click)="addUserPressed()" type="submit">Toevoegen</button>
          </li>
      </ul>
    </div>
    <div class="card-body">
      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let user of users">
              <td>
                  {{ user.id }}
              </td>
            <td>
              <a class="selectable" (click)="userInfoPressed(user)">{{ user.email }}</a>
            </td>
            <td>
              {{ user.firstName }} {{ user.lastName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
