<div class="card">
  <div class="card-header">
    <ul class="nav nav-pills card-header-pills">
      <li class="nav-item mr-auto">
        <h3>Dashboard</h3>
      </li>
    </ul>
  </div>

  <div class="card-deck">
    <statistics-card [data]="statistics.totalChatbots" [title]="'Aantal chatbots'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalConversations" [title]="'Aantal conversaties'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalMessages" [title]="'Aantal berichten'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalUsers" [title]="'Aantal gebruikers'" class="card"></statistics-card>
  </div>

  <div class="card-deck">
    <statistics-card [data]="statistics.totalJuiceAccounts" [title]="'Juice licenties'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalSpruitAccounts" [title]="'Spruit licenties'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalMoestuinAccounts" [title]="'Moestuin licenties'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalKasAccounts" [title]="'Kas licenties'" class="card"></statistics-card>
  </div>

  <div class="card-deck">
    <statistics-card [data]="statistics.totalForeverFreeAccounts" [title]="'Forever Free licenties'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalEssentialAccounts" [title]="'Essential licenties'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalTeamAccounts" [title]="'Team licenties'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalProfessionalAccounts" [title]="'Professional licenties'" class="card"></statistics-card>
  </div>

  <div class="card-deck">
    <statistics-card [data]="statistics.totalConversationsToday" [title]="'Aantal conversaties vandaag'" class="card"></statistics-card>
    <statistics-card [data]="statistics.totalMessagesToday" [title]="'Aantal berichten vandaag'" class="card"></statistics-card>
  </div>
</div>
